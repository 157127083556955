import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Heading from '../../components/shared/heading';
import { PricesTitleWrap } from './title.stc';

const PricesTitleArea = ({ titleCSS, taglineCSS }) => {
  const PricesTitleData = useStaticQuery(graphql`
    query PricesTitleQuery {
      pricesJson(id: { eq: "prices-page-header" }) {
        title
        tagline
      }
    }
  `);

  const { title, tagline } = PricesTitleData.pricesJson;

  return (
    <PricesTitleWrap>
      <Container>
        <Row>
          <Col lg={{ size: 10, offset: 1 }} xl={{ size: 7, offset: 2 }}>
            {title && <Heading {...titleCSS}>{title}</Heading>}
            {tagline && <Heading {...taglineCSS}>{tagline}</Heading>}
          </Col>
        </Row>
      </Container>
    </PricesTitleWrap>
  );
};

PricesTitleArea.defaultProps = {
  titleCSS: {
    as: 'h5',
    fontSize: [1],
    textTransform: 'capitalize',
    mb: '17px'
  },
  taglineCSS: {
    color: 'secondaryColor',
    fontSize: [4, 5],
    fontWeight: 'bold',
    lineHeight: [1.4, 1.375]
  }
};

export default PricesTitleArea;
