import React from 'react';
import SEO from '../components/seo';
import Layout from '../containers/layout/layout';
import Header from '../containers/layout/header';
import Footer from '../containers/layout/footer';
import PricesTitle from '../containers/prices/title';
import PricesBanner from '../containers/prices/banner';
import PricesContent from '../containers/prices/content';
import InstagramWrap from '../containers/global/instagram';

const PricesPage = () => (
  <Layout>
    <SEO title="Cennik" />
    <Header />
    <div className="main-content">
      <PricesTitle />
      <PricesBanner />
      <PricesContent />
      <InstagramWrap />
    </div>
    <Footer />
  </Layout>
);

export default PricesPage;
