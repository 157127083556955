import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Image from '../../components/image';
import { PricesBannerWrap } from './banner.stc';

const PricesBanner = () => {
  const pricesBannerData = useStaticQuery(graphql`
    query PricesBannerQuery {
      pricesJson(id: { eq: "prices-page-banner" }) {
        banner {
          childImageSharp {
            fluid(
              maxWidth: 1260
              maxHeight: 585
              quality: 100
              srcSetBreakpoints: 6
            ) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  `);
  const bannerImg = pricesBannerData.pricesJson.banner.childImageSharp.fluid;

  return (
    <PricesBannerWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <Image fluid={bannerImg} alt="Cennik" />
          </Col>
        </Row>
      </Container>
    </PricesBannerWrap>
  );
};

export default PricesBanner;
